<template>
  <div class="selecao-cliente-input overflow-x-hidden" @keydown.esc="fecharModal()">
    <div class="d-flex fl<ex-row align-items-baseline col-12 h-2 p-0" v-if="!cadastroPessoaAberto">
      <span v-show="!isLoading">
        <i class="fa fa-search busca-modal-icon" />
      </span>
      <span v-show="isLoading">
        <i class="fas fa-spinner fa-spin busca-modal-icon" />
      </span>

      <input
        type="text"
        class="input-pesquisa pl-2 w-100"
        id="lmxta-selecaocliente-input-pesquisa"
        :value="search"
        @input="search = $event.target.value"
        autocomplete="off"
        placeholder="Pesquisar..."
        ref="item"
      />

      <button
        type="button"
        id="lmxta-selecaocliente-btn-fechar"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        @click="fecharModal()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <hr class="m-0 p-0 w-100" v-if="!cadastroPessoaAberto" />
    <a
      href="javascript:void(0)"
      class="link-busca lmxta-selecaocliente-btn-selecionar"
      v-for="(cliente, index) in results"
      :key="index"
      v-bind:if="results.length > 0 && !cadastroPessoaAberto"
      @click="selecionarCliente(cliente)"
      v-show="!cadastroPessoaAberto"
    >
      <div class="row mt-1 tela opcao-click">
        <div class="col-12">
          <i class="fa fa-user-alt ml-2" aria-hidden="true"></i>
          <span class="nome-tela ml-2">{{ getDadosClienteListagem(cliente) }}</span>
        </div>
      </div>
    </a>
    <a href="javascript:void(0)" class="link-busca" v-if="results.length == 0 && !cadastroPessoaAberto">
      <div class="row mt-1 tela">
        <div class="col-12">
          <span class="nome-tela ml-2 font-italic"> {{ labelPlaceholderInput }} </span>
        </div>
      </div>
    </a>

    <div class="row mt-3">
      <div class="col-12">
        <button
          class="btn btn-sm btn-block btn-primary"
          value="Cadastrar Cliente"
          @click.stop="abrirCadastroPessoa()"
          v-if="permiteCadastro && !cadastroPessoaAberto"
          id="lmxta-selecaocliente-btn-cadastrar"
        >
          Cadastrar Cliente
        </button>
        <cadastro-pessoa v-model="retornoCadastroPessoa" v-if="cadastroPessoaAberto" />
      </div>
    </div>
  </div>
</template>

<script>
  import CrmService from '@/core/services/crm.service';
  import CadastroPessoa from '@/components/shared/crm/CrmCadastro';
  import swal from '@/common/alerts';
  import Vue from 'vue';

  export default {
    name: 'SelecaoClienteInput',
    components: {
      CadastroPessoa,
    },
    props: {
      buscarPorCodigo: null,
      tipoCrmPesquisa: String,
      permiteCadastro: {
        type: Boolean,
        default: false,
      },
      filtrarSomenteParaIndicacao: {
        type: Boolean,
        default: false,
      },
      botaoNaoEspecificar: {
        type: Boolean,
        default: false,
      },
      invalido: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isOpen: false,
        isHelp: true,
        notFound: false,
        isLoading: false,
        results: [],
        search: '',
        arrowCounter: 0,
        selectedResult: null,
        apiService: null,
        resultsLimit: 30,
        cadastroPessoaAberto: false,
        retornoCadastroPessoa: null,
      };
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);

      window.setTimeout(function () {
        document.querySelector('#lmxta-selecaocliente-input-pesquisa').focus();
      }, 200);

      if (this.buscarPorCodigo && Number(this.buscarPorCodigo)) this.setResultById(this.buscarPorCodigo);
    },
    computed: {
      labelClienteFornecedor() {
        switch (this.tipoCrmPesquisa) {
          case 'C':
            return 'cliente';
          case 'F':
            return 'fornecedor';
          case 'T':
            return 'transportador';
          case 'A':
            return 'cliente/fornecedor';
          default:
            return 'cliente';
        }
      },

      hasExtraResults() {
        return this.results && this.results.length >= this.resultsLimit;
      },

      labelPlaceholderInput() {
        return 'Encontre seu ' + this.labelClienteFornecedor + ' pelo nome, CPF ou CNPJ ou código.';
      },

      permiteRealizarCadastro() {
        // atualmente só é compatíve com cadastro de clientes PF e PJ
        return this.permiteCadastro && (this.tipoCrmPesquisa === 'C' || this.tipoCrmPesquisa === 'A');
      },
    },
    watch: {
      search() {
        this.filterResults();
      },
      isLoading(val, oldValue) {
        if (val) {
          this.isHelp = false;
        }
      },
      selectedResult(val, oldValue) {
        if (val) this.selecionarCliente(val);
      },
      retornoCadastroPessoa(val, oldValue) {
        if (val) {
          if (this.cadastroPessoaAberto) this.cadastroPessoaAberto = false;

          if (val.salvou) {
            this.selecionarPessoaCadastrada();
          }
        }
      },
    },
    methods: {
      getDadosClienteListagem(cliente) {
        const documentoCliente = cliente.TipoPessoa === 'F' ? 'CPF' : 'CNPJ';
        return `${cliente.Nome} (CÓD: ${cliente.Codigo} | ${documentoCliente}: ${cliente.Documento})`;
      },

      selecionarCliente(cliente) {
        if (cliente.CEPCliente.trim() == '') {
          this.$emit('close');
          this.$emit('');

          Vue.swal({
            icon: 'error',
            title: 'A operação não pode ser realizada',
            html:
              `O fornecedor <strong>${cliente.Nome}</strong> não possui CEP preenchido no cadastro (necessário para a emissão da nota fiscal). Para prosseguir com a devolução, atualize o cadastro do cliente.` +
              '</br></br> ' +
              '<button type="button" class="btn-closed swal2-confirm btn btn-primary mr-1 rounded mt-1" aria-label="" style="display: inline-block;">FECHAR</button>',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            buttonsStyling: false,

            onBeforeOpen: () => {
              const closed = document.querySelector('.btn-closed');

              closed.addEventListener('click', () => {
                Vue.swal.close();
              });
            },
          });

          return;
        }

        this.$emit('input', cliente);
        this.search = '';
      },

      fecharModal() {
        this.search = '';
        this.cadastroPessoaAberto = false;
        this.$emit('close');
      },

      filterResults() {
        var searchCriteria = this.search.trim().toLowerCase();
        this.isLoading = true;
        var self = this;

        setTimeout(() => {
          if (searchCriteria != this.search.trim().toLowerCase()) return;

          if (!searchCriteria) {
            this.results = [];
            this.selectedResult = null;
            this.arrowCounter = 0;
            this.isLoading = false;
            this.isHelp = true;
            this.notFound = false;
            return;
          }
          var query =
            'Pesquisa=' +
            searchCriteria +
            '&Tipo=' +
            this.getCrmTypeApi() +
            '&FiltrarSomenteParaIndicacao=' +
            this.filtrarSomenteParaIndicacao;

          CrmService.listar(query)
            .then((response) => {
              let resultado = response.data;
              self.results = resultado;
              self.arrowCounter = 0;
              self.isLoading = false;
              self.isHelp = resultado == 0;
              self.notFound = self.isHelp;
            })
            .catch((error) => {
              swal.exibirMensagemErro('Ocorreu um problema');
              self.isLoading = false;
              return;
            });
        }, 700);
      },

      getCrmTypeApi() {
        switch (this.tipoCrmPesquisa) {
          case 'C':
            return 1;
          case 'F':
            return 2;
          case 'T':
            return 4;
          case 'A':
            return 3;
          default:
            return 1;
        }
      },

      resultDisplay(result) {
        var display = '';

        if (result.TipoPessoa == 'F') {
          display = result.Nome;

          if (result.Documento) {
            display = display + ' (CPF: ' + result.Documento + ' | ';
          } else {
            display = display + ' (';
          }

          if (result.DataNascimento) {
            display = display + 'Nasc: ' + this.formatDate(result.DataNascimento) + ' | Código: ' + result.Codigo + ')';
          } else {
            display = display + 'Código: ' + result.Codigo + ')';
          }
        } else {
          display = result.NomeFantasia;

          if (result.NomeFantasia == result.Nome) {
            display = display + ' (CNPJ: ' + result.Documento + ' | Código: ' + result.Codigo + ')';
          } else {
            display = display + ' (CNPJ: ' + result.Documento + ' | Razão: ' + result.Nome + ' | Código: ' + result.Codigo + ')';
          }
        }

        return display;
      },

      formatDate(data) {
        if (!data) return '';

        var dataJs = new Date(data);
        var dia = String(dataJs.getDate());
        var mes = String(dataJs.getMonth() + 1);
        var ano = dataJs.getFullYear();
        dia = dia.length == 2 ? dia : '0' + dia;
        mes = mes.length == 2 ? mes : '0' + mes;

        return dia + '/' + mes + '/' + ano;
      },

      setResult(result) {
        this.isOpen = false;
        this.selectedResult = result;

        if (!result) this.search = '';
      },

      setDefaultResult() {
        var defaultCrm = {
          Codigo: 1,
          TipoPessoa: 'F',
          Nome: 'CONSUMIDOR FINAL',
        };
        this.setResult(defaultCrm);
      },

      setResultById(idResult) {
        this.isLoading = true;

        setTimeout(() => {
          this.isLoading = false;

          if (!idResult) {
            this.results = [];
            this.selectedResult = null;
            this.arrowCounter = 0;
            this.isHelp = true;
            this.notFound = false;
            return;
          }
        }, 700);
      },

      onArrowDown(evt) {
        if (this.arrowCounter < this.results.length - 1) {
          this.arrowCounter = this.arrowCounter + 1;
        } else {
          this.arrowCounter = 0;
        }
      },

      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        }
      },

      onTab(evt) {
        if (!this.isOpen) return;

        this.onEnter(evt);
      },

      onEnter(evt) {
        this.selectedResult = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;

        if (this.selectedResult) {
          var nextTabIndex = this.$refs.clienteInput.tabIndex + 1;
          var $nextInput = document.querySelectorAll("[tabindex='" + nextTabIndex + "']");

          if ($nextInput.length > 0) {
            $nextInput[0].focus();
          }
        }
      },

      onBlur(evt) {
        if (!this.selectedResult) {
          this.selectedResult = null;
          this.search = '';
        }
      },

      onFocus() {
        if (this.results.length == 0) {
          this.isOpen = true;
          this.isHelp = true;
        }
      },

      clearInput() {
        this.selectedResult = null;
        this.search = '';
        this.$refs.clienteInput.focus();
      },

      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      },

      getAbsoluteUrl() {
        return window.location.href.split('gestor_web')[0];
      },

      abrirCadastroPessoa() {
        this.isOpen = false;
        this.arrowCounter = -1;
        this.retornoCadastroPessoa = null;
        this.cadastroPessoaAberto = true;
      },

      selecionarPessoaCadastrada() {
        var documentoFormatado = '';
        if (this.retornoCadastroPessoa.dados.TipoPessoa === 'F')
          documentoFormatado = this.mascaraCpf(this.retornoCadastroPessoa.dados.Documento);
        else documentoFormatado = this.mascaraCnpj(this.retornoCadastroPessoa.dados.Documento);

        var pessoaSelecionada = {
          Codigo: this.retornoCadastroPessoa.dados.Id,
          TipoPessoa: this.retornoCadastroPessoa.dados.TipoPessoa,
          Nome: this.retornoCadastroPessoa.dados.Nome,
          NomeFantasia: this.retornoCadastroPessoa.dados.Nome,
          Documento: documentoFormatado,
        };

        this.setResult(pessoaSelecionada);
      },

      mascaraCpf: function (valor) {
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
      },
      mascaraCnpj: function (valor) {
        return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
      },
    },
  };
</script>

<style scoped>
  .fz-95 {
    font-size: 95% !important;
  }

  .fz-90 {
    font-size: 90% !important;
  }

  .fz-85 {
    font-size: 85% !important;
  }

  .img-size-4 {
    width: 5%;
  }

  .img-size-2 {
    width: 3%;
  }

  .lx-1x3x {
    font-size: 1.3rem !important;
  }

  .busca-modal-icon {
    font-size: 20px;
  }

  .modal-pesquisa {
    overflow-y: auto;
    overflow-x: hidden !important;
  }

  .modal-pesquisa .modal-dialog {
    max-width: 100%;
  }

  .modal-pesquisa .input-pesquisa,
  .modal-pesquisa .selecao-cliente-input input {
    border: 0 !important;
    background: transparent;
    font-size: 20px;
    height: 40px;
    border: 0px;
    width: 60%;
    padding-bottom: 0px;
  }

  .modal-pesquisa .input-pesquisa:focus {
    outline: none;
  }

  .modal-pesquisa .input-pesquisa::-ms-clear {
    display: none;
  }

  .modal-pesquisa .input-pesquisa:-ms-input-placeholder {
    color: black;
  }

  .modal-pesquisa .busca-modal-icon {
    font-size: 20px;
  }

  .modal-pesquisa .descricao-tela {
    font-style: italic;
  }

  .modal-pesquisa .nome-tela {
    font-weight: 500;
    font-size: 15px;
  }

  .modal-pesquisa .modal-footer {
    background: #ebebeb;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    height: 33px;
  }

  .modal-pesquisa .esc-icon {
    width: 30px;
    height: 15px;
    font-size: 9px;
    background: #333;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    display: inline-block;
  }

  .modal-pesquisa .fa-level-down {
    font-weight: 700;
  }

  .modal-pesquisa .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-pesquisa .link-busca {
    text-decoration: none;
    color: #333;
  }

  .modal-pesquisa .pai:not(:first-child) {
    margin-top: 20px;
  }

  .selecao-cliente-input {
    position: relative;
  }

  .link-busca {
    text-decoration: none;
    color: #333;
  }

  .selecao-cliente-input-results {
    padding: 0;
    margin: 0;
    border: 1px solid #dee2e6;
    min-width: 300px;
    overflow-x: hidden;
    position: absolute;
    top: 31px;
    left: 0px;
    z-index: 1000;
    max-height: 450px;
    width: 100%;
    background: #fff;
    overflow: hidden;
  }

  .selecao-cliente-input-results-scroll {
    overflow: auto;
  }

  .selecao-cliente-input-results .loading {
    padding-top: 20px;
    text-align: center;
  }

  .selecao-cliente-input-results .help {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }

  .selecao-cliente-input-results .external-link {
    padding: 3px;
    border-top: 1px solid #dee2e6;
    text-align: right;
  }

  .selecao-cliente-input-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    font-size: 90%;
    overflow-x: hidden;
  }

  .selecao-cliente-input-result.is-active,
  .selecao-cliente-input-result:hover {
    background-color: #ffb200;
    color: white;
  }

  .lmx-icon-gradient {
    color: #5b2e90;
    display: block;
    background: -webkit-linear-gradient(#2c004b, #5b2e90);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>
