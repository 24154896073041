<template>
	<BaseModal
		@close="closeModalFormulario"
		:classesAdicionaisModal="'modal m-3 rounded overflow-x-hidden w-90 w-sm-100 position-relative'"
		:tituloModal="'Nova Importação de Produtos'"
		:classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
		:classesAdicionaisFooter="'p-0 bg-white d-flex justify-content-center align-items-center w-100'"
		:classesAdicionaisBody="'py-2 overflow-x-hidden'"
		id="modalNovaImportaco"
		:telaInteira="true"
	>
		<template v-if="isLoading" v-slot:loading>
			<BaseLoading nameLoading="loading-erp" :isLoadingFullScreen="false" :labelLoading="labelLoading" />
		</template>

		<template v-else v-slot:body>
			<div class="container-fluid">
				<div class="row">
					<div class="col-12 pt-4 pb-1">
						<div class="card mb-4">
							<div class="card-body">
								<div class="row">
									<div class="col-xs-12 col-sm-12 col-md-12 col-lg-1">
										<img src="@/assets/Images/linx-icon-ilustrativo/support.png" class="mx-auto d-block" />
									</div>
									<div class="col">
										<p class="card-text">
											Através desta rotina você poderá importar vários produtos através de um arquivo
											padronizado.
										</p>
										<p class="card-text">
											Para baixar o arquivo modelo que deve ser preenchido,
											<a href="javascript:void(0)" class="font-weight-bold" @click="obterArquivoModelo()"
												>clique aqui</a
											>.
										</p>
										<p class="card-text">
											Dúvidas sobre o preenchimento do arquivo?
											<a
												href="https://share.linx.com.br/pages/viewpage.action?pageId=212512559"
												target="_blank"
												class="font-weight-bold"
												>Consulte nossa documentação.</a
											>
										</p>
										<p v-if="isFromCadastroProdutos" class="card-text">
											Para visualizar as suas importações,
											<router-link class="font-weight-bold" to="../importacao-produtos">
												clique aqui
											</router-link>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="row my-3">
							<div class="col-sm-12 col-md-12 col-xl-12 mx-0 mt-2 mb-3">
								<BaseUpload
									@result-arquivo-upload="setaArquivoParaUpload"
									:fileType="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
								></BaseUpload>
								<span id="msg-erro-arquivo"></span>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1 input-required">Identificador dos Produtos</label>
								<VueMultiselect
									v-model="formulario.identificador"
									:options="listas.identificadores"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
								<span id="msg-erro-identificador"></span>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1 input-required">Formato do código de barras</label>
								<VueMultiselect
									v-model="formulario.formatoCodigoDeBarras"
									:options="listas.tiposCodigoBarras"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
								<span id="msg-erro-codigo-barras"></span>
							</div>
							<div id="div-produtos-importacao-empresas" class="col-sm-12 col-md-6 col-xl-4 my-2" v-if="controleTela.ProdutoDisponivelEmpresa">
								<label class="m-1">Empresas</label>
								<span
									v-tooltip.bottom="
										apresentarTooltip(
											'Ao utilizar este campo o sistema ignorará a empresa que consta na planilha e considerará as empresas selecionadas neste campo para todos o produtos da planilha.',
										)
									"
								>
									<i class="fa fa-info-circle" aria-hidden="true" style="color: #5b2e90"></i>
								</span>
								<VueMultiselect
									v-model="formulario.empresas"
									id="select-produtos-importacao-empresas"
									:options="listas.empresas"
									:multiple="true"
									track-by="Id"
									:customLabel="({ Id, Nome }) => `${Id} - ${Nome}`"
									placeholder="Selecione..."
									:preselect-first="false"
									selected-Label="Selecionado"
									select-group-label="Clique para selecionar todos"
									deselect-group-label="Clique para desmarcar todos"
									select-label="Selecionar"
									deselect-label="Remover"
									:close-on-select="false"
									:group-select="true"
									:clear-on-select="true"
									group-values="empresas"
									group-label="grupo"
									:preserve-search="false"
								>
									<template slot="selection" slot-scope="select">
										<span
											class="multiselect__single"
											v-if="select.values.length == 1 &amp;&amp; !select.isOpen"
										>
											<div v-for="(item, idx) in select.values" :key="idx" style="float: left">
												{{ item.Id + ' - ' + item.Nome }}
											</div>
										</span>
										<span
											class="multiselect__single"
											v-if="select.values.length > 1 &amp;&amp; !select.isOpen"
										>
											<div style="float: left">
												{{ select.values.length + ' itens selecionados.' }}
											</div>
										</span>
									</template>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1" for="custo-icms-padrao">Custo ICMS padrão</label>
								<Money
									v-model="formulario.custoICMS"
									@keydown.native="allowOnlyNumbers($event)"
									class="form-control form-control-sm"
									v-bind="casaDecimalAlteradaMoeda"
									maxlength="13"
									id="custo-icms-padrao"
									style="height: 33px"
								></Money>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1" for="preco-venda-padrao">Preço de venda padrão</label>
								<Money
									v-model="formulario.precoVenda"
									@keydown.native="allowOnlyNumbers($event)"
									class="form-control form-control-sm"
									v-bind="casaDecimalAlteradaMoeda"
									maxlength="13"
									id="preco-venda-padrao"
									style="height: 33px"
								></Money>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">Identificador da Estrutura Mercadológica</label>
								<VueMultiselect
									id="multiselect-tipos-verificao-estruturas-mercadologicas"
									v-model="formulario.tipoVerificacaoCA"
									:options="listas.tiposVerificacaoCA"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">Fornecedor padrão</label>
								<VueMultiselect
									v-model="formulario.fornecedor"
									:options="listas.fornecedores"
									track-by="Codigo"
									label="NomeCliente"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">Configuração tributária padrão</label>
								<VueMultiselect
									v-model="formulario.configuracaoTributaria"
									:options="listas.configuracoesTributarias"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.setores.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.setor"
									:options="listas.setores.Estruturas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.linhas.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.linha"
									:options="linhasFiltradas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.marcas.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.marca"
									:options="marcasFiltradas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.colecoes.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.colecao"
									:options="colecoesFiltradas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.espessuras.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.espessura"
									:options="listas.espessuras.Estruturas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.classificacoes.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.classificacao"
									:options="listas.classificacoes.Estruturas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.tamanhos.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.tamanho"
									:options="listas.tamanhos.Estruturas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="col-sm-12 col-md-6 col-xl-4 my-2">
								<label class="m-1">{{ listas.cores.LegendaDinamica }} padrão</label>
								<VueMultiselect
									v-model="formulario.cor"
									:options="coresFiltradas"
									track-by="Id"
									label="Descricao"
									placeholder="Selecione..."
									:close-on-select="false"
									:allow-empty="true"
									:group-select="false"
									:clear-on-select="true"
									:preserve-search="false"
									selected-label="Selecionado"
									select-label="Selecionar"
									deselect-label="Remover"
									:preselect-first="false"
								>
									<span slot="noResult">Nenhum registro encontrado</span>
									<span slot="noOptions">Selecione um registro</span>
								</VueMultiselect>
							</div>
							<div class="row col-12 mt-3">
								<div class="col-sm-12 col-md-6 col-xl-4 my-2">
									<div class="custom-control custom-checkbox">
										<input
											class="custom-control-input"
											id="cadastrosAuxiliaresAutomatico"
											type="checkbox"
											v-model="formulario.isCadastrarAutomaticamenteEstruturas"
										/>
										<label class="custom-control-label" for="cadastrosAuxiliaresAutomatico"
											>Criar cadastros auxiliares (estrutura mercadológica, Unidade de Venda, NCM,
											CEST)</label
										>
									</div>
									<div class="custom-control custom-checkbox">
										<input
											class="custom-control-input"
											id="cadastroCodebarCodigoProduto"
											type="checkbox"
											v-model="formulario.isCadastrarCodebarCodigoProduto"
										/>
										<label class="custom-control-label" for="cadastroCodebarCodigoProduto"
											>Cadastrar código do produto como código de barras
											<span
												v-tooltip.bottom="
													apresentarTooltip(
														'O código do produto será cadastrado como código de barras, mesmo que seja informado um código de barras no arquivo de importação. Válido apenas para novos cadastros.',
													)
												"
											>
												<i class="fa fa-info-circle" aria-hidden="true" style="color: #5b2e90"></i>
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-slot:footer>
			<div class="modal-footer d-flex w-100 p-0">
				<button class="btn btn-primary flex-grow-1" @click="executaUploadFormulario">
					<span>IMPORTAR</span>
				</button>
			</div>
		</template>
	</BaseModal>
</template>

<script>
	/* eslint-disable vue/no-side-effects-in-computed-properties */

	import VueMultiselect from 'vue-multiselect';
	import { mapGetters } from 'vuex';
	import { Money } from 'v-money';
	import { mapWaitingActions } from 'vue-wait';
	import VTooltip from 'v-tooltip';
	import Vue from 'vue';

	import swal from '@/common/alerts';
	import { formatarValorMonetario, allowOnlyNumbers } from '@/common/number';
	import { EventBus } from '@/common/eventBus';

	Vue.use(VTooltip);

	export default {
		name: 'ImportacaoModalFormulario',
		components: {
			Money,
			VueMultiselect,
		},
		props: {
			isFromCadastroProdutos: { type: Boolean, default: false },
		},
		data() {
			return {
				formulario: {
					arquivo: null,
					nomeArquivo: '',
					identificador: '',
					formatoCodigoDeBarras: '',
					custoICMS: '',
					precoVenda: formatarValorMonetario(0),
					fornecedor: '',
					configuracaoTributaria: '',
					setor: '',
					linha: '',
					marca: '',
					colecao: '',
					espessura: '',
					classificacao: '',
					tamanho: '',
					cor: '',
					tipoVerificacaoCA: '',
					isCadastrarAutomaticamenteEstruturas: false,
					isGerarNovaReferenciaAutomatica: false,
					isCadastrarCodebarCodigoProduto: false,
					empresas: [],
				},
				listas: {
					identificadores: [],
					tiposCodigoBarras: [],
					fornecedores: [],
					empresas: [],
					configuracoesTributarias: [],
					tiposVerificacaoCA: [],
					setores: {
						LegendaDinamica: '',
						Estruturas: [],
					},
					linhas: {
						LegendaDinamica: '',
						Estruturas: [],
					},
					marcas: {
						LegendaDinamica: '',
						Estruturas: [],
					},
					colecoes: {
						LegendaDinamica: '',
						Estruturas: [],
					},
					espessuras: {
						LegendaDinamica: '',
						Estruturas: [],
					},
					classificacoes: {
						LegendaDinamica: '',
						Estruturas: [],
					},
					tamanhos: {
						LegendaDinamica: '',
						Estruturas: [],
					},
					cores: {
						LegendaDinamica: '',
						Estruturas: [],
					},
				},
				controleTela: {
					PermitirIncluirNovosProdutos: false,
					PermitirAlterarExcluirProdutos: false,
					BloqueiaCadatroProdutoNaoIntegrado: false,
					ProdutoDisponivelEmpresa: false,
				},
			};
		},
		computed: {
			...mapGetters('importacao', {
				isLoading: 'isImportacaoModalFormularioLoading',
				labelLoading: 'labelLoading',
			}),
			casaDecimalAlteradaMoeda() {
				var TiposMoedaReal = {
					decimal: ',',
					thousands: '.',
					prefix: 'R$ ',
					precision: 2,
				};

				return TiposMoedaReal;
			},
			linhasFiltradas() {
				var linhas = [];

				if (this.listas.linhas.Estruturas.length !== 0) {
					if (!this.formulario.setor) {
						linhas = this.listas.linhas.Estruturas.filter((linha) => {
							return linha.IdsAssociados.length == 0;
						});
					} else {
						linhas = this.listas.linhas.Estruturas.filter((linha) => {
							return linha.IdsAssociados.includes(this.formulario.setor.Id) || linha.IdsAssociados.length == 0;
						});
					}

					if (this.formulario.linha) {
						var linhaSelecionadaEstaNaLista = linhas.find((linha) => {
							return linha.Id == this.formulario.linha?.Id;
						});
						if (!linhaSelecionadaEstaNaLista) this.formulario.linha = null;
					}
				}

				return linhas;
			},
			marcasFiltradas() {
				var marcas = [];

				if (this.listas.marcas.Estruturas.length !== 0) {
					if (!this.formulario.linha) {
						marcas = this.listas.marcas.Estruturas.filter((marca) => {
							return marca.IdsAssociados.length == 0;
						});
					} else {
						marcas = this.listas.marcas.Estruturas.filter((marca) => {
							return marca.IdsAssociados.includes(this.formulario.linha.Id) || marca.IdsAssociados.length == 0;
						});
					}
					if (this.formulario.linha && this.formulario.marca?.Id) {
						var marcaSelecionadaEstaNaLista = marcas.find((marca) => {
							return marca.Id == this.formulario.marca.Id;
						});
						if (!marcaSelecionadaEstaNaLista) this.formulario.marca = null;
					}
				}

				return marcas;
			},
			colecoesFiltradas() {
				var colecoes = [];

				if (this.listas.colecoes.Estruturas.length !== 0) {
					if (!this.formulario.marca) {
						colecoes = this.listas.colecoes.Estruturas.filter((colecao) => {
							return colecao.IdsAssociados.length == 0;
						});
					} else {
						colecoes = this.listas.colecoes.Estruturas.filter((colecao) => {
							return colecao.IdsAssociados.includes(this.formulario.marca.Id) || colecao.IdsAssociados.length == 0;
						});
					}
					if (this.formulario.colecao) {
						var colecaoSelecionadaEstaNaLista = colecoes.find((colecao) => {
							return colecao.Id == this.formulario.colecao.Id;
						});

						if (!colecaoSelecionadaEstaNaLista) this.formulario.colecao = null;
					}
				}

				return colecoes;
			},
			coresFiltradas() {
				var cores = [];

				if (this.listas.cores.Estruturas.length !== 0) {
					if (!this.formulario.marca) {
						cores = this.listas.cores.Estruturas.filter((cor) => {
							return cor.IdsAssociados.length == 0;
						});
					} else {
						cores = this.listas.cores.Estruturas.filter((cor) => {
							return cor.IdsAssociados.includes(this.formulario.marca.Id) || cor.IdsAssociados.length == 0;
						});
					}
					if (this.formulario.cor) {
						var corSelecionadaEstaNaLista = cores.find((cor) => {
							return cor.Id == this.formulario.cor.Id;
						});

						if (!corSelecionadaEstaNaLista) this.formulario.cor = null;
					}
				}

				return cores;
			},
			bootstrapConflict() {
				return {
					template:
						'<div class="tooltip-vue max-width-tooltip" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
					arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
					innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
				};
			},
		},
		methods: {
			...mapWaitingActions('importacao', {
				buscarDadosIniciaisFormulario: 'buscarDadosIniciaisFormulario importacao',
				importarProdutosPorArquivo: 'importarProdutosPorArquivo importacao',
			}),
			obterArquivoModelo() {
				this.$store.dispatch('importacao/obterArquivoModelo').catch((erro) => {
					let mensagemErro = '';

					if (erro.response) {
						if (erro.response.status == 400) mensagemErro = erro.data;
						else mensagemErro = erro.message;
					} else mensagemErro = erro;

					swal.exibirMensagemErro(mensagemErro);
					return Promise.reject(mensagemErro);
				});
			},
			abrirModalNovaImportacao() {
				this.habitaModalNovaImportacao().then(() => {
					if (!this.controleTela.PermitirIncluirNovosProdutos) {
						swal.exibirMensagemAlerta(
							'Atenção',
							this.tipoMensagemSwal.Aviso,
							'Não é possível cadastrar uma nova importação de produtos,pois o usuário não possui a permissão <b>Incluir novos produtos</b>.',
						);
					} else if (!this.controleTela.PermitirAlterarExcluirProdutos) {
						swal.exibirMensagemAlerta(
							'Atenção',
							this.tipoMensagemSwal.Aviso,
							'Não é possível cadastrar uma nova importação de produtos, pois o usuário não possui a permissão <b>Alterar/Excluir Produtos</b>.',
						);
					} else if (this.controleTela.BloqueiaCadatroProdutoNaoIntegrado) {
						swal.exibirMensagemAlerta(
							'Atenção',
							this.tipoMensagemSwal.Aviso,
							'Não é possível cadastrar uma nova importação de produtos, pois o parâmetro <b> Bloqueia cadastro de produto não integrado</b> está habilitado.',
						);
					}
				});
			},
			closeModalFormulario() {
				this.limparFormularioAoFechar();
				this.$store.commit('importacao/updateIsShowModalFormularioToClosed');
			},
			limparFormularioAoFechar() {
				var formulario = {
					arquivo: null,
					nomeArquivo: '',
					identificador: '',
					formatoCodigoDeBarras: '',
					custoICMS: '',
					precoVenda: formatarValorMonetario(0),
					fornecedor: '',
					configuracaoTributaria: '',
					setor: '',
					linha: '',
					marca: '',
					colecao: '',
					espessura: '',
					classificacao: '',
					tamanho: '',
					cor: '',
					isCadastrarAutomaticamenteEstruturas: false,
					isGerarNovaReferenciaAutomatica: false,
					isCadastrarCodebarCodigoProduto: false,
					empresas: [],
				};
				this.formulario = formulario;
			},
			buscarDadosIniciaisFormularioImportacao() {
				this.buscarDadosIniciaisFormulario()
					.then((jsonResponse) => {
						this.dadosParaFormulario = jsonResponse;
						this.preencherDadosIniciaisFormulario();
					})
					.catch((erro) => {
						let mensagemErro = '';

						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						swal.exibirMensagemErro(mensagemErro);
						return Promise.reject(mensagemErro);
					});
			},
			preencherDadosIniciaisFormulario() {
				Object.assign(this.listas.setores, this.dadosParaFormulario.Setores);
				Object.assign(this.listas.linhas, this.dadosParaFormulario.Linhas);
				Object.assign(this.listas.marcas, this.dadosParaFormulario.Marcas);
				Object.assign(this.listas.espessuras, this.dadosParaFormulario.Espessuras);
				Object.assign(this.listas.colecoes, this.dadosParaFormulario.Colecoes);
				Object.assign(this.listas.cores, this.dadosParaFormulario.Cores);
				Object.assign(this.listas.tamanhos, this.dadosParaFormulario.Tamanhos);
				Object.assign(this.listas.classificacoes, this.dadosParaFormulario.Classificacoes);

				this.listas.fornecedores = this.dadosParaFormulario.Fornecedores;
				this.listas.tiposCodigoBarras = this.dadosParaFormulario.TiposCodebar;
				this.listas.configuracoesTributarias = this.dadosParaFormulario.ConfiguracoesTributarias;
				this.listas.identificadores = this.dadosParaFormulario.Identificadores;
				this.listas.tiposVerificacaoCA = this.dadosParaFormulario.TiposVerificacaoCA;
				this.listas.empresas = [
					{
						grupo: 'Selecionar Todos',
						empresas: this.$store.state.importacao.listaEmpresas,
					},
				];
				this.controleTela.ProdutoDisponivelEmpresa =
					this.$store.state.importacao.parametrosGlobais.ProdutoDisponivelEmpresa;
			},
			validaCamposFormulario() {
				var erros = [];

				if (!this.formulario.arquivo)
					erros.push({
						msg: 'Arquivo é um campo obrigatório',
						id: 'msg-erro-arquivo',
					});
				if (!this.formulario.identificador)
					erros.push({
						msg: 'Identificador é um campo obrigatório',
						id: 'msg-erro-identificador',
					});
				if (!this.formulario.formatoCodigoDeBarras)
					erros.push({
						msg: 'Código de barras é um campo obrigatório',
						id: 'msg-erro-codigo-barras',
					});

				this.criaLabelErro(erros);

				return erros;
			},
			executaUploadFormulario() {
				var hasErros = this.validaCamposFormulario();
				if (hasErros.length > 0) return;

				var commandEnvioFormulario = this.montaCommandEnvioFormulario();

				this.importarProdutosPorArquivo(commandEnvioFormulario)
					.then(() => {
						swal.exibirToastSucesso(
							'A importação foi iniciada com sucesso.<br>Acompanhe em tempo real o status no painel de importações.',
						);
					})
					.catch((erro) => {
						let mensagemErro = '';

						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.response.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						swal.exibirMensagemErro(mensagemErro);
						return Promise.reject(mensagemErro);
					});
			},
			setaArquivoParaUpload(file) {
				const reader = new FileReader();

				reader.onload = () => {
					var arquivoBase64 = reader.result.split(',')[1];
					this.formulario.arquivo = arquivoBase64;
					this.formulario.nomeArquivo = file[0].name;
				};

				reader.readAsDataURL(file[0]);
			},
			montaCommandEnvioFormulario() {
				var commandEnvioFormulario = {
					ArquivoPreenchido: this.formulario.arquivo,
					NomeArquivo: this.formulario.nomeArquivo,
					Identificador: this.formulario.identificador.Id,
					CodigoDeBarras: this.formulario.formatoCodigoDeBarras.Id,
					ICMS: this.formulario.custoICMS,
					PrecoVenda: this.formulario.precoVenda,
					IdFornecedor: this.formulario.fornecedor.Codigo,
					IdConfiguracaoTributaria: this.formulario.configuracaoTributaria.Id,
					IdSetor: this.formulario.setor?.Id,
					IdLinha: this.formulario.linha?.Id,
					IdMarca: this.formulario.marca?.Id,
					IdColecao: this.formulario.colecao?.Id,
					IdEspessura: this.formulario.espessura.Id,
					IdClassificacao: this.formulario.classificacao.Id,
					IdTamanho: this.formulario.tamanho.Id,
					IdCor: this.formulario.cor.Id,
					CadastrarEstruturaMercadologica: this.formulario.isCadastrarAutomaticamenteEstruturas,
					GerarNovaReferenciaAutomatica: this.formulario.isGerarNovaReferenciaAutomatica,
					CadastrarCodebarCodigoProduto: this.formulario.isCadastrarCodebarCodigoProduto,
					TipoVerificacaoCA: this.formulario.tipoVerificacaoCA.Id,
					IdsEmpresas: this.controleTela.ProdutoDisponivelEmpresa
						? this.formulario.empresas.map((empresa) => empresa.Id)
						: [],
				};

				return commandEnvioFormulario;
			},
			habitaModalNovaImportacao() {
				this.controleTela.PermitirIncluirNovosProdutos = this.$store.state.PermissoesUsuario.IncluirNovosProdutos;
				this.controleTela.PermitirAlterarExcluirProdutos = this.$store.state.PermissoesUsuario.AlterarExcluirProdutos;
				this.controleTela.BloqueiaCadatroProdutoNaoIntegrado =
					this.$store.state.ParametrosGlobais.BloqueiaCadastroProdutoNaoIntegrado;
				this.controleTela.ProdutoDisponivelEmpresa = this.$store.state.ParametrosGlobais.ProdutoDisponivelEmpresa;
				return Promise.resolve();
			},
			allowOnlyNumbers(event) {
				return allowOnlyNumbers(event);
			},
			criaLabelErro(erros) {
				document.querySelectorAll('.erro').forEach((errorELement) => {
					global.window.$(errorELement).remove();
				});

				var errosNaoDuplicados = this.removeItemsDuplicados(erros);

				errosNaoDuplicados.forEach((erro) => {
					var errorElement = document.createElement('span');
					var errorNode = document.createTextNode(erro.msg);
					errorElement.className = 'erro';

					errorElement.appendChild(errorNode);

					var element = document.getElementById(erro.id);

					if (element) global.window.$('#' + erro.id).after(errorElement);
				});
			},
			removeItemsDuplicados(array = []) {
				return array.filter((value, index) => {
					return array.indexOf(value) === index;
				});
			},
			apresentarTooltip(mensagem) {
				return { content: mensagem, ...this.bootstrapConflict };
			},
			removerArquivo() {
				this.formulario.arquivo = null;
			},
		},
		mounted() {
			this.buscarDadosIniciaisFormularioImportacao();
			EventBus.$on('remover-arquivo', this.removerArquivo);
		},
	};
</script>

<style>
	.erro {
		font-size: 12px;
		color: red;
		padding: 0 2px;
	}

	.max-width-tooltip {
		max-width: 400px;
	}
</style>
